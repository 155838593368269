
import Vue from "vue";

export default Vue.extend({
  name: "pwaUpdater",
  watch: {
    "$store.state.sw.update": {
      immediate: true,
      async handler(val): Promise<void> {
        if (!val || !this.$store.state.pwaRefresh) return;

        await this.wait(2000);

        this.$store.state.snackbar = true;
      },
    },
  },
  methods: {
    async wait(time: number): Promise<void> {
      await new Promise<void>((resolve) => setTimeout(() => resolve(), time));
    },
    update(): void {
      this.$store.dispatch("update");
    },
    ignore(): void {
      this.$store.commit("pwaRefresh", false);
      this.$store.commit("snackbar", false);
    },
  },
});
